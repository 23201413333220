import { nbspifyHtml } from "../../../core/utils/nbspifyHtml.js";
import { thumb } from "../../../core/utils/thumb.js";

function convertImagesToPicture(storageUrl: string, html: string, loading: "lazy" | "eager"): string {
	return html.replace(
		/<img(?<p1>[^>]+)src="(?<src>[^"]+)"(?<p2>[^>]*)>/gu,
		(_match, p1: string, src: string, p2: string) => {
			return `
				<picture class="js-picture">
					<source
						srcset="${thumb(storageUrl, 540, 0, src)} 1x"
						type="image/webp"
						media="(min-width: 577px)"
					/>
					<source
						srcset="${thumb(storageUrl, 530, 0, src)} 1x"
						type="image/webp"
						media="(max-width: 576px)"
					/>
					<a aria-label="Zobrazit náhled obrázku v obsahu" href="${thumb(storageUrl, 1440, 0, src)}" data-fslightbox data-type="image">
						<img${p1}alt="Obrázek v obsahu" loading=${loading} src="${thumb(storageUrl, 636, 0, src)}"${p2}>
					</a>
				</picture>
			`;
		},
	);
}

function createOverflowTable(html: string): string {
	return html.replace(/<table[^>]*>(?<content>.*?)<\/table>/gsu, (_match, content: string) => {
		return `
			<div class="responsive-table">
				<table>${content}</table>
			</div>
		`;
	});
}

export function transformCkEditorContent(
	storageUrl: string,
	html: string,
	loading: "lazy" | "eager",
	contentClass: string | false,
): string {
	const withConvertedImages = convertImagesToPicture(storageUrl, html, loading);
	const withOverflowedTables = createOverflowTable(withConvertedImages);
	const htmlElement = document.createElement("div");
	htmlElement.innerHTML = withOverflowedTables;
	const withNbsp = nbspifyHtml(htmlElement);
	return contentClass !== false ? `<div class="${contentClass}">${withNbsp}</div>` : withNbsp;
}
