<script lang="ts">
	import "../assets/css/ckeditor.css";
	import { transformCkEditorContent } from "../utils/transformCkEditorContent.js";
	import { getGlobalSettings } from "../getGlobalSettings.js";

	export let content: string;
	export let contentClass: string | false = "ckeditor-content";
	export let loading: "eager" | "lazy" = "lazy";

	const { STORAGE_URL } = getGlobalSettings();

	/* eslint-disable svelte/no-at-html-tags */
</script>

{@html transformCkEditorContent(STORAGE_URL, content, loading, contentClass)}
